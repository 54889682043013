/* Tailwind CSS */
@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

body {
  font-family: 'Roboto', sans-serif;
}

input,
select,
button,
textarea,
a {
  border-radius: 0;
  -webkit-appearance: none;
}

/* And Design Customize Start */

.ant-btn-primary {
  @apply text-textColorWhite bg-primaryColor border-primaryColor hover:bg-primaryColorHover hover:border-primaryColorHover disabled:text-gray-200 disabled:bg-blue-400 disabled:border-blue-400;
}

.ant-divider-horizontal.ant-divider-with-text-center::before,
.ant-divider-horizontal.ant-divider-with-text-center::after {
  border-top: 2px solid !important;
  @apply border-primaryColor text-center;
}

.ant-message-custom-content {
  @apply inline-flex items-center;
}

.ant-popover-title {
  @apply bg-backgroundColorBlack;
}

.ant-image-preview-img-wrapper img {
  display: inline !important;
}

.ant-image-preview-img {
  margin: auto !important;
}

/* And Design Customize End */

/* App Header And Footer Style Start */

.app-header {
  @apply !bg-backgroundColorBlack flex flex-row items-center justify-between px-2 sticky top-0 z-50 md:px-12 lg:px-24;
}

.header-title {
  @apply !text-textColorWhite !text-[1.4rem] ml-0.5 mt-3.5 uppercase whitespace-nowrap hover:!text-textColorWhiteHover md:ml-2;
}

.btn-connect-wallet {
  @apply !bg-yellow-400 !text-textColorWhite w-[145px] ml-2 border-none hover:!bg-yellow-500;
}

/* App Header And Footer Style End */

/* Token Related Component Style Start */

.input-field-table-th {
  @apply min-w-[200px] px-6 py-2 text-gray-800 whitespace-nowrap text-start md:min-w-[300px] lg:min-w-[400px];
}

.values-table-th {
  @apply min-w-[220px] px-6 py-2 text-gray-800 whitespace-nowrap text-start md:min-w-[300px] lg:min-w-[340px] xl:min-w-[400px];
}

/* Token Related Component Style End */

/* KYC Application Style Start */

.kyc-application-form-group {
  @apply flex flex-col items-center justify-between space-x-2 md:flex-row;
}

.kyc-application-form-item {
  @apply min-w-full md:min-w-[50%];
}

.kyc-application-form-submit-btn {
  @apply w-[300px] font-bold mt-4;
}

/* KYC Application Style End */

/* Dashboard Components Style Start */

.dashboard-card {
  @apply border text-sm flex items-center justify-between;
}

/* Dashboard Components Style End */

/* Data Table Style Start */

.search-and-filter-box {
  @apply flex flex-col items-center justify-between space-x-0 space-y-2 sm:flex-row sm:space-x-2 sm:space-y-0;
}

.table-thead {
  @apply text-gray-600 text-xs font-semibold uppercase tracking-wider text-left px-5 py-3 bg-gray-100 border-gray-200 border-b-2 hover:cursor-default;
}

.table-thead-th {
  @apply border-b-2 border-t-2 py-3 px-3 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider whitespace-nowrap;
}

.table-body-tr {
  @apply hover:bg-gray-100 hover:cursor-default;
}

.table-body-td {
  @apply py-3 px-3 border-b border-gray-200 text-gray-900 text-sm capitalize whitespace-nowrap;
}

/* Data Table Style End */
